import React, { useState } from 'react';
import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar';
import { LogOutModal } from '../../components/LogOutModal';

export const Ajuda = () => {
  

    return (<>
    <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar />
                        <div className="container-fluid">


                        <div className="card shadow mb-4">


<div className="card-header py-3">
<iframe height='600px' width='100%' src="https://docs.google.com/document/d/e/2PACX-1vRHB8nQ2vhcw3JIJkhC9WUOfmYNRNQtgpdpTMmx-wFqcNF39SazTQxnkFn9Vkp6x0LV1ILGJbeoImLA/pub?embedded=true"></iframe>
</div>
</div>



      


              


                                </div>
                    </div>
             
                </div>
            </div>
            <LogOutModal />
        </>
                                )
}



