import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';

import api from '../../../config/configApi';

export const EditProfileImage = () => {

    const [image, setImage] = useState('');
    const [endImg, setEndImg] = useState('');
    //const [endImg, setEndImg] = useState(localStorage.getItem('image'));    

    const [status, setStatus] = useState({
        type: '',
        mensagem: ''
    });

    const editUser = async e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('image', image);

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }

        await api.put("/profile/edit-profile-image", formData, headers)
            .then((response) => {
                localStorage.setItem('image', response.data.image);
                setStatus({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setStatus({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setStatus({
                        type: 'error',
                        mensagem: 'Erro: Tente mais tarde!'
                    });
                }
            });
    }

    useEffect(() => {
        const getUser = async () => {

            const headers = {
                'headers': {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }

            await api.get("/profile/view-profile", headers)
                .then((response) => {
                    if (response.data.user) {
                        setEndImg(response.data.endImage);
                    } else {
                        setStatus({
                            type: 'redWarning',
                            mensagem: "Erro: Usuário não encontrado!"
                        });
                    }

                }).catch((err) => {
                    if (err.response) {
                        setStatus({
                            type: 'redWarning',
                            mensagem: err.response.data.mensagem
                        });
                    } else {
                        setStatus({
                            type: 'redWarning',
                            mensagem: "Erro: Tente mais tarde!"
                        });
                    }
                })
        }

        getUser();
    }, []);

    return (    <>
        <div id="wrapper">
            <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Navbar />
                    <div className="container-fluid">

        <div className="card shadow mb-4">


            <div className="card-header py-3">


                <div className="row">

                    <div className="col-xl-10 col-md-6 mb-4">
                        <h6 class="m-0 font-weight-bold text-primary">Editar a Foto</h6>
                    </div>

                    <div className="col-xl-2 col-md-6 mb-4">
                        <Link to="/view-profile">
                            <button type="button" className="btn btn-info btn-block">Perfil</button>
                        </Link>
                    </div>

                    <div className="col-xl-2 col-md-6 mb-4">
                    </div>

                </div>

            </div>

            <div className="card-body">

                <div className="alert-content-adm">
                    {status.type === 'redSuccess' ? <Redirect to={{
                        pathname: '/view-profile',
                        state: {
                            type: "success",
                            mensagem: status.mensagem
                        }
                    }} /> : ""}
                    {status.type === 'error' ? <p className="alert-danger">{status.mensagem}</p> : ""}
                </div>

                <form onSubmit={editUser}>

                    <div className="form-group row">
                        <div className="col-sm-12">
                            <label className="title-input">Imagem</label>
                            <input type="file" name="image" id="image" className="form-control" placeholder="Senha para acessar o sistema" onChange={e => setImage(e.target.files[0])} />
                        </div>

                        <div className="col-sm-12">
                            {image ? <img src={URL.createObjectURL(image)} alt="Imagem do usuário" width="150" height="150" /> : <img src={endImg} alt="Imagem do usuário" width="150" height="150" />}
                        </div>
                    </div>

                    <div className="form-group row">
                                <button type="submit" className="btn btn-success btn-block">Salvar</button>
                                </div>

                </form>
            </div>




        </div>
        </div>
                    </div>
                </div>
            </div>
            <LogOutModal />
        </>
    )
}