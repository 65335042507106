import 'moment/locale/pt-br'

import React, { useState } from 'react';
import { useHistory, Link, Redirect } from 'react-router-dom';
import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';
import * as yup from 'yup';

import api from '../../../config/configApi';
import { now } from 'lodash';
import moment from 'moment/moment';

export const AddChamado = () => {

    
    const history = useHistory();

    const [user, setUser] = useState({
        nome: '',
        dataabertura: moment(new Date()).format('DD/MM/YYYY HH:mm'),
        whatsapp: '',
        sala: '',
        descricaoatendimento: '',
        situacao: 'Em Andamento',
        email: '',
    });

    const [status, setStatus] = useState({
        type: '',
        mensagem: ''
    });

    const valueInput = e => setUser({ ...user, [e.target.name]: e.target.value });

    const addUser = async e => {
        e.preventDefault();

        if (!(await validate())) return;

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };

        await api.post('/chamado/chamado', user, headers)
            .then((response) => {
                setStatus({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
                alert("Chamado Cadastrado com sucesso")
                return history.push("/chamados/");
            }).catch((err) => {
                if (err.response) {
                    setStatus({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setStatus({
                        type: 'error',
                        mensagem: "Erro: Tente novamente!"
                    });
                }
            });
    }

    async function validate() {
        let schema = yup.object().shape({
            
            email: yup.string("Erro: Necessário preencher o campo e-mail!")
                .email("Erro: Necessário preencher o campo e-mail!")
                .required("Erro: Necessário preencher o campo e-mail!"),
            nome: yup.string("Erro: Necessário preencher o campo nome!")
                .required("Erro: Necessário preencher o campo nome!")
        });

        try {
            await schema.validate({
                nome: user.nome,
                email: user.email,
            });
            return true;
        } catch (err) {
            setStatus({
                type: 'error',
                mensagem: err.errors
            });
            return false;
        }
    }

    return (<>
  <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar />
                        <div className="container-fluid">
<div class="card shadow mb-4">
                            <div class="card-header py-3">

                                <div className="alert-content-adm">
                                    {status.type === 'redWarning' ?
                                        <Redirect to={{
                                            pathname: '/chamados',
                                            state: {
                                                type: "error",
                                                mensagem: status.mensagem
                                            }
                                        }} /> : ""}
                                    {status.type === 'redSuccess' ? <Redirect to={{
                                        pathname: '/chamados',
                                        state: {
                                            type: "success",
                                            mensagem: status.mensagem
                                        }
                                    }} /> : ""}
                                    {status.type === 'error' ? <p className="alert-danger">{status.mensagem}</p> : ""}
                                </div>

                                <div className="row">

                                    <div className="col-xl-10 col-md-6 mb-4">
                                        <h6 class="m-0 font-weight-bold text-primary">Cadastrar Usuário</h6>
                                    </div>

                                    <div className="col-xl-2 col-md-6 mb-4">

                                        <Link to="/chamados"><button type="button" className="btn btn-info btn-block">Listar</button></Link>{" "}

                                    </div>
                                </div>



                            </div>
                            <div class="card-body">
                                <form onSubmit={addUser} className="form-adm">

                                
                                    <div class="form-group row">
                                        <label for="staticEmail" className="col-sm-2 col-form-label">Nome(*)</label>
                                        <div className="col-sm-10">
                                            <input type="text" name="nome" id="nome" className="form-control" placeholder="Nome completo do usuário" onChange={valueInput} required/>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="staticEmail" className="col-sm-2 col-form-label">Whatsapp(*)</label>
                                        <div className="col-sm-10">
                                            <input type="text" name="whatsapp" id="whatsapp" className="form-control" placeholder="whatsapp" onChange={valueInput} required/>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group row">

                                        <label class="my-1 mr-2" for="staticEmail">Sala(*)</label>
                                        <select class="custom-select my-1 mr-sm-2" name="sala" id="sala" placeholder="sala" onChange={valueInput} required >


                                            <option value="">Selecione a Sala</option>
                                            <option value="3232">3232</option>
                                            <option value="3110">3110</option>
                                            <option value="Angra">Angra</option>
                                        </select>

                                    </div>

                                    <div class="form-group row">
                                        <label for="staticEmail" className="col-sm-2 col-form-label">descricaoatendimento</label>
                                        <div className="col-sm-10">
                                            <input type="text" name="descricaoatendimento" id="descricaoatendimento" className="form-control" placeholder="descricaoatendimento" onChange={valueInput} />
                                        </div>
                                    </div>

                              
                                    <div class="form-group row">
                                        <label for="staticEmail" className="col-sm-2 col-form-label">E-mail(*)</label>
                                        <div className="col-sm-10">
                                            <input type="email" name="email" id="email" className="form-control" placeholder="Melhor e-mail do usuário" onChange={valueInput} required/>
                                        </div>
                                    </div>

                             
                                    <div class="form-group row">

                                        <button type="submit" className="btn btn-success  btn-block">Salvar</button>
                                    </div>


                                </form>
                            </div>


                        </div>

                        </div>
                    </div>
                </div>
            </div>
            <LogOutModal />
    </>);
};