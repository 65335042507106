const estadosCivis = {
  1: 'Solteiro',
  2: 'Casado',
  3: 'Separado',
  4: 'Divorciado',
  5: 'Viúvo',
};

const formularioPadraoContent = ({
  nome,
  bairro,
  cidade,
  email,
  indicacao,
  beneficio,
  telefoneprincipal,
  telefonesegundo,
  telefoneoutros,
  senhaINSS,
  estadoCivil,

}) => {
  let bairroMunicipio;
  if (bairro && cidade) {
    bairroMunicipio = [bairro, cidade].filter((i) => i).join('/');
  } else {
    bairroMunicipio = '_'.repeat(30);
  }

  let telefones;
  if (telefoneprincipal || telefonesegundo || telefoneoutros) {
    telefones = [telefoneprincipal, telefonesegundo, telefoneoutros]
      .filter((i) => i)
      .join(' / ');
  } else {
    telefones = '_'.repeat(20);
  }

  return {
    content: [
      {
        columns: [
          {
            alignment: 'left',
            text: [
              { text: 'ATENDENTE\n', fontSize: 10, bold: true },
              {
                text: localStorage.getItem('name').toUpperCase(),
                fontSize: 10,
                bold: false,
                decoration: 'underline',
              },
            ],
          },
          {
            text: [
              { text: 'Email:\n', fontSize: 11, bold: true },
              {
                text: email?.toUpperCase() || `\n${' '}`,
                fontSize: 10,
                bold: false,
                decoration: 'underline',
              },
            ],
          },
          {
            alignment: 'right',
            text: [
              { text: 'SENHA INSS\n', fontSize: 10, bold: true },
              {
                text: `${senhaINSS || '___________'}`,
                fontSize: 10,
                bold: false,
                decoration: 'underline',
              },
            ],
          },
        ],
      },
      {
        text: '\n\nFORMULÁRIO PADRÃO PARA ATENDIMENTO \n\n',
        style: 'header',
      },
      {
        text: [
          { text: 'NOME COMPLETO/DADOS\n', fontSize: 11, bold: true },
          {
            text: nome.toUpperCase() || `\n${'_'.repeat(60)}`,
            fontSize: 10,
            bold: false,
            decoration: 'underline',
          },
        ],
      },
      {
        text: [
          { text: '\nBAIRRO/MUNICÍPIO: ', fontSize: 11, bold: true },
          {
            text: bairroMunicipio,
            fontSize: 10,
            bold: false,
            decoration: 'underline',
          },
          { text: ' INDICAÇÃO: ', fontSize: 11, bold: true },
          {
            text: indicacao || '_'.repeat(15),
            fontSize: 10,
            bold: false,
            decoration: 'underline',
          },
        ],
      },
      {
        text: [
          {
            text: '\nTIPO DE BENEFÍCIO REQUERIDO:\n',
            fontSize: 11,
            bold: true,
          },
          {
            text: `${beneficio || '_'.repeat(20)}\n`,
            fontSize: 10,
            bold: false,
            decoration: 'underline',
          },
        ],
      },
      {
        text: [
          { text: '\nCONTATOS (telefone):\n', fontSize: 11, bold: true },
          {
            text: `${telefones}\n\n`,
            fontSize: 10,
            bold: false,
            decoration: 'underline',
          },
        ],
      },
      {
        text: 'INFORMAÇÕES DO REQUERENTE:',
        style: 'header',
        alignment: 'justify',
      },
      {
        text: 'Possui inscrição no Cadastro Único para Programas Sociais do Governo Federal -',
        fontSize: 12,
        bold: true,
        margin: [0, 3],
      },
      {
        text: [
          { text: 'CadÚnico?', fontSize: 12, bold: true },
          { text: `   SIM ___ OU   NÃO ___`, fontSize: 12, bold: false },
        ],
        margin: [0, 3],
      },
      {
        text: [
          { text: 'Obs.:', fontSize: 12, bold: true },
          { text: '_'.repeat(68), fontSize: 12, bold: false },
        ],
        margin: [0, 3],
      },
      {
        text: '(caso NÃO - informar que irá providenciar e informar ao escritório os dados, especialmente a composição do grupo familiar informada ao CadÚnico e a renda por pessoa da família)',
        fontSize: 10,
        margin: [0, 3, 0, 0],
      },
      {
        text: [
          {
            text: '\nSeu Cadastro Único foi atualizado há menos de dois anos?',
            fontSize: 12,
            bold: true,
          },
          { text: '\nSIM ___    NÃO ___', fontSize: 11, bold: false },
        ],
      },
      {
        text: [
          { text: '\nQual seu estado civil?\n', fontSize: 12, bold: true },
          {
            text: `Resposta: ${estadosCivis[estadoCivil] || '_'.repeat(59)}`,
            fontSize: 12,
            bold: false,
          },
        ],
      },
      {
        text: [
          { text: '\nOnde você mora?', fontSize: 12, bold: true },
          { text: '_'.repeat(53), fontSize: 12, bold: false },
          {
            text: '\nRespostas possíveis:',
            fontSize: 11,
            bold: true,
            decoration: 'underline',
          },
          {
            text: 'Sozinho numa residência; ou Moro em residência com pessoa (s) da família; ou Moro numa instituição de acolhimento; ou Moro na rua.',
            fontSize: 11,
            bold: false,
          },
        ],
      },
      {
        text: [
          { text: '\nComposição do grupo familiar:', fontSize: 10, bold: true },
          {
            text: '\nDeclaro que informei a composição do grupo familiar do mesmo modo informado no Cadastro Único,',
            fontSize: 10,
            bold: false,
          },
          {
            text: 'de acordo com o formulário do anexo I – Requerimento do Benefício de Prestação Continuada da Assistência Social – BPC e Composição do Grupo Familiar.',
            fontSize: 10,
            bold: true,
          },
        ],
      },
      {
        text: [
          {
            text: '\nAlém das pessoas já informadas, há algum outro membro que componha o grupo familiar ou algum membro que não possua número de CPF?',
            fontSize: 10,
            bold: true,
          },
          { text: '\nSIM ___    NÃO ___', fontSize: 10, bold: false },
        ],
      },
      {
        text: [
          {
            text: 'Caso SIM informar os dados da pessoa do grupo familiar:\n',
            fontSize: 10,
            bold: true,
          },
          { text: '_'.repeat(65), fontSize: 12, bold: false },
          { text: '\n' + '_'.repeat(65), fontSize: 12, bold: false },
        ],
      },
      {
        text: [
          {
            text: '\nVocê recebe algum benefício do INSS ou de outro órgão, exceto Bolsa Família?',
            fontSize: 12,
            bold: true,
          },
          { text: '\nSIM ___ OU   NÃO ___', fontSize: 11, bold: false },
        ],
      },
      {
        text: '\nVocê é estrangeiro em situação regular no Brasil? Sim ___ ou Não ___.',
        fontSize: 11,
        bold: true,
      },
      {
        text: '\nDeclaro sob as penas da lei, que as informações prestadas neste formulário são completas e verdadeiras, estando ciente das penalidades previstas nos artigos 171 e 299 do Código Penal Brasileiro.',
        fontSize: 10,
        bold: true,
      },
      {
        text: '\nRio de Janeiro, RJ, _____ de __________________ de 20    .',
        fontSize: 11,
        bold: true,
      },
      {
        text: '\n\n' + '_'.repeat(60),
        fontSize: 11,
        bold: false,
        alignment: 'center',
      },
      {
        text: '\nAssinatura do Requerente',
        fontSize: 11,
        bold: true,
        alignment: 'center',
        breakPage: 'after',
      },
      {
        text: [
          {
            text: 'Pêndencias: \n',
            bold: true,
          },
          {
            text: '_________________________________________________________\n',
          },
          {
            text: '_________________________________________________________\n',
          },
          {
            text: '_________________________________________________________\n',
          },
          {
            text: '_________________________________________________________\n',
          },
          {
            text: '_________________________________________________________\n',
          },
          {
            text: '_________________________________________________________\n',
          },
          {
            text: '_________________________________________________________\n\n',
          },
        ],
      },
      {
        text: 'Observação:',
        bold: true,
      },
      {
        text: '\n\nDigitalizado____________________________',
        bold: true,
      },

      {
        text: '\n\nEnviado________________________________',
        bold: true,
      },
    ],
    styles: {
      header: {
        fontSize: 12,
        alignment: 'center',
        bold: true,
      },
    },
    defaultStyle: {
      font: 'Arial',
    },
  };
};

export { formularioPadraoContent };
