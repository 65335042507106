import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import * as yup from 'yup';
import { Link, Redirect } from 'react-router-dom';
import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';

import { LogOutModal } from '../../../components/LogOutModal';

import 'moment/locale/pt-br';
import api from '../../../config/configApi';
import { servDeleteUser } from '../../../services/servDeleteUser';

    export const EditUser = (props) => {
    const moment = require('moment');
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [empresaId, setEmpresaId] = useState('');
    const [equipeId, setEquipeId] = useState('');
    const [perfilId, setPerfilId] = useState('');
    const [outraId, setOutraId] = useState('');
    const [matricula, setMatricula] = useState('');
    const [celular, setCelular] = useState('');
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [datanascimento, setDataNascimento] = useState('');
    const [isActive, setIsActive] = useState(null);
    const [id] = useState(props.match.params.id);
    const [selectedSetores, setSelectedSetores] = useState([]);
    const [showModal, setShowModal] = useState(false); 
    const [tempStatus, setTempStatus] = useState(isActive); 
    const [setoresOptions, setSetoresOptions] = useState([]);
    const getSetores = async () => {
    try {
      const requestHeaders = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };

      const { data } = await api.get('/setores', {
        headers: requestHeaders,
      });

      const formattedSetores = data.map((setor) => ({
        value: setor.id,
        label: setor.nome,
      }));

      setSetoresOptions(formattedSetores);
    } catch (err) {
      console.error('Erro ao carregar setores:', err);
    }
  };

  useEffect(() => {
    getSetores();
  }, []);

    const handleStatusChange = (status) => {
        if (status === 'Inativo') {
            setTempStatus(false);
            setShowModal(true);
        } else {
            setIsActive(true);
        }
    };

    const confirmStatusChange = () => {
    setIsActive(tempStatus);
    setShowModal(false);
};

    const [status, setStatus] = useState({
        type: '',
        mensagem: ''
    });

        const editUser = async e => {
         e.preventDefault();

        if (!(await validate())) return;

            const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }
        
        const departmentIds = selectedSetores.map(option => option.value).join(', ') || "";

        await api.put("/user/user", { id, nome, email, celular, empresaId, equipeId, perfilId, outraId, matricula, cep, logradouro, numero, complemento, bairro, cidade, datanascimento, departmentIds, isActive }, headers)
            .then((response) => {
                setStatus({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setStatus({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setStatus({
                        type: 'error',
                        mensagem: 'Erro: Tente mais tarde!'
                    });
                }
            });
    }

        useEffect(() => {
        const getUser = async () => {

            const headers = {
                'headers': {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }

            await api.get("/user/user/" + id, headers)
                .then((response) => {
                    if (response.data.user) {
                        setNome(response.data.user.nome);
                        setEmail(response.data.user.email);
                        setEmpresaId(response.data.user.empresaId);
                        setEquipeId(response.data.user.equipeId);
                        setPerfilId(response.data.user.perfilId);
                        setCelular(response.data.user.celular);
                        setOutraId(response.data.user.outraId);
                        setMatricula(response.data.user.matricula);
                        setCep(response.data.user.cep);
                        setLogradouro(response.data.user.logradouro);
                        setNumero(response.data.user.numero);
                        setComplemento(response.data.user.complemento);
                        setBairro(response.data.user.bairro);
                        setCidade(response.data.user.cidade);
                        setDataNascimento(response.data.user.datanascimento);
                        setIsActive(response.data.user.isActive);
                        if (response?.data?.user?.departments?.length) {
                            const userStores = response?.data?.user?.departments?.map((s) => {
                                return { value: s.id, label: s.nome}
                            });
                            setSelectedSetores(userStores);
                        }
                        
                    } else {
                        setStatus({
                            type: 'redWarning',
                            mensagem: "Erro: Usuário não encontrado!"
                        });
                    }

                }).catch((err) => {
                    if (err.response) {
                        setStatus({
                            type: 'redWarning',
                            mensagem: err.response.data.mensagem
                        });
                    } else {
                        setStatus({
                            type: 'redWarning',
                            mensagem: "Erro: Tente mais tarde!"
                        });
                    }
                })
        }

        getUser();
    }, [id]);

    async function validate() {
        let schema = yup.object().shape({
            email: yup.string("Erro: Necessário preencher o campo e-mail!")
                .email("Erro: Necessário preencher o campo e-mail!")
                .required("Erro: Necessário preencher o campo e-mail!"),
            nome: yup.string("Erro: Necessário preencher o campo nome!")
                .required("Erro: Necessário preencher o campo nome!")

        });

        try {
            await schema.validate({ nome, email});
            return true;
        } catch (err) {
            setStatus({ type: 'error', mensagem: err.errors });
            return false;
        }
    }

    const deleteUser = async (idUser) => {
        const response = await servDeleteUser(idUser);
        if (response) {
            if (response.type === "success") {
                setStatus({
                    type: 'redSuccess',
                    mensagem: response.mensagem
                });
            } else {
                setStatus({
                    type: "error",
                    mensagem: response.mensagem
                });
            }
        } else {
            setStatus({
                type: 'error',
                mensagem: 'Erro: Tente mais tarde!'
            });
        }
    }
    return (
        <>
            <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar />
                        <div className="container-fluid">
                            <div class="card shadow mb-4">
                                <div class="card-header py-3">

                                    <div className="alert-content-adm">
                                        {status.type === 'redWarning' ?
                                            <Redirect to={{
                                                pathname: '/users',
                                                state: {
                                                    type: "error",
                                                    mensagem: status.mensagem
                                                }
                                            }} /> : ""}
                                        {status.type === 'redSuccess' ? <Redirect to={{
                                            pathname: '/users',
                                            state: {
                                                type: "success",
                                                mensagem: status.mensagem
                                            }
                                        }} /> : ""}
                                        {status.type === 'error' ? <p className="alert-danger">{status.mensagem}</p> : ""}
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-8 col-md-6 mb-4">
                                            <h6 class="m-0 font-weight-bold text-primary">Editar Usuário: {nome} </h6>
                                        </div>

                                        <div className="col-xl-2 col-md-6 mb-4">

                                            <Link to="/users"><button type="button" className="btn btn-info btn-block">Listar</button></Link>{" "}

                                        </div>
                                        <div className="col-xl-2 col-md-6 mb-4">
                                            <Link to={"/view-user/" + id}> <button type="button" className="btn btn-primary  btn-block">Visualizar</button>  </Link>{" "}

                                        </div>
                                      

                                    </div>



                                </div>


                                <div class="card-body">
                                    <form onSubmit={editUser} className="form-adm">


                                    <>

                                    <div class="form-row">
                                        
                                        <div class="form-group col-md-6">
                                            <label for="inputPassword4">Nome</label>
                                            <input type="text" name="nome" id="nome" className="form-control" placeholder="Nome completo do usuário" value={nome} onChange={text => setNome(text.target.value)} />
                                        </div>

                                        <div class="form-group col-md-5">
                                            <label for="inputEmail4">Email</label>
                                            <input type="email" name="email" id="email" className="form-control" placeholder="Melhor e-mail do usuário" value={email} onChange={text => setEmail(text.target.value)} />
                                        </div>

                                        <div class="form-group col-md-1">
                                            <label for="inputPassword4">Nascimento</label>
                                            <input type="text" name="datanascimento" id="datanascimento" className="form-control" autoComplete="on" value={datanascimento} onChange={text => setDataNascimento(text.target.value)} />
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="form-group col-md-1">
                                            <label for="inputEmail4">CEP</label>
                                            <input type="text" name="cep" id="cep" className="form-control" autoComplete="on" value={cep} onChange={text => setCep(text.target.value)} />
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label for="inputPassword4">Logradouro</label>
                                            <input type="text" name="logradouro" id="logradouro" className="form-control" autoComplete="on" value={logradouro} onChange={text => setLogradouro(text.target.value)} />
                                        </div>
                                        <div class="form-group col-md-1">
                                            <label for="inputPassword4">Número</label>
                                            <input type="text" name="numero" id="numero" className="form-control" autoComplete="on" value={numero} onChange={text => setNumero(text.target.value)} />
                                        </div>
                                        <div class="form-group col-md-2">
                                            <label for="inputPassword4">Complemento</label>
                                            <input type="text" name="complemento" id="complemento" className="form-control" autoComplete="on" value={complemento} onChange={text => setComplemento(text.target.value)} />
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label for="inputPassword4">Bairro</label>
                                            <input type="text" name="bairro" id="bairro" className="form-control" autoComplete="on" value={bairro} onChange={text => setBairro(text.target.value)} />
                                        </div>

                                        <div class="form-group col-md-3">
                                            <label for="inputPassword4">Cidade</label>
                                            <input type="text" name="cidade" id="cidade" className="form-control" autoComplete="on" value={cidade} onChange={text => setCidade(text.target.value)} />
                                        </div>
                                    </div>


                                    <div class="form-row">
                                        <div class="form-group col-md-2">
                                            <label for="inputEmail4">Empresa</label>

                                            <select class="custom-select my-1 mr-sm-2" name="empresaId" id="empresaId" placeholder="Empresa Usuário" value={empresaId} onChange={text => setEmpresaId(text.target.value)}>
                                                    <option value="">Selecione o Empresa</option>
                                                    <option value="1">Marcelo Manhaes</option>
                                                </select>
                                        </div>

                                        <div class="form-group col-md-2">
                                            <label for="inputEmail4">Equipe</label>
                                            <select class="custom-select my-1 mr-sm-2" name="equipeId" id="equipeId" placeholder="Equipe Usuário" value={equipeId} onChange={text => setEquipeId(text.target.value)}>
                                                    <option value="">Selecione a Equipe</option>
                                                    <option value="1">Padrão</option>
                                                </select>


                                        </div>

                                        
                                        <div class="form-group col-md-2">
                                                <label for="inputEmail4">Outra</label>
                                                <select class="custom-select my-1 mr-sm-2" name="empresaId" id="outraId" placeholder="Outra Usuário" value={outraId} onChange={text => setOutraId(text.target.value)} >
                                                    <option value="">Selecione o Outra</option>
                                                    <option value="1">Outra</option>
                                                </select>
                                                
                                                
                                            </div>       

                                        <div class="form-group col-md-2">
                                            <label for="inputEmail4">Perfil</label>
                                            <select class="custom-select my-1 mr-sm-2" name="perfilId" id="perfilId" placeholder="Perfil Usuário" value={perfilId} onChange={text => setPerfilId(text.target.value)} >
                                                    <option value="">Selecione o Perfil</option>
                                                    <option value="1">Master</option>
                                                    
                                                </select>

                                        </div>


                                         <div class="form-group col-md-2">
                                            <label htmlFor="statusUsuario">Status</label>
                                            <select  class="custom-select" value={isActive ? "Ativo" : "Inativo"} onChange={(e) => handleStatusChange(e.target.value)}style={{ marginTop: '4px', height: '39px' }}>
                                            <option value="Ativo">Ativo</option>
                                            <option value="Inativo">Inativo</option>
                                        </select>
                                        </div>
                                        <Modal show={showModal} onHide={() => setShowModal(false)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Confirmação</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>Tem certeza que deseja inativar este usuário?</Modal.Body>
                                            <Modal.Footer>
                                                <button className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancelar</button>
                                                <button className="btn btn-danger" onClick={confirmStatusChange}>Inativar</button>
                                            </Modal.Footer>
                                        </Modal>

                                        <div class="form-group col-md-2">
                                            <label for="inputPassword4">Celular</label>
                                            <input type="text" name="celular" id="celular" className="form-control"  value={celular} onChange={text => setCelular(text.target.value)} style={{ marginTop: '4px', height: '39px' }} />
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="setoresSelect"> Setores</label>
                                            <Select
                                            id="setoresSelect"
                                            isMulti
                                            options={setoresOptions}
                                            value={selectedSetores}
                                            onChange={setSelectedSetores}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            />
                                        </div>

                                        

                                    </div>

                                </>

                                      
                                   

                                            <div class="form-group row">

                                            <button type="submit" className="btn btn-success  btn-block">Salvar</button>
                                        </div>


                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <LogOutModal />
        </>
    )
        };