import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import * as yup from 'yup';

import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar';
import api from '../../config/configApi';
import { servDeleteUser } from '../../services/servDeleteUser';

export const EditRioAguas = (props) => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [perfil, setPerfil] = useState('');
    const [empresaId, setEmpresaId] = useState('');
    const [equipeId, setEquipeId] = useState('');
    const [matricula, setMatricula] = useState('');
    const [id] = useState(props.match.params.id);

    const [status, setStatus] = useState({
        type: '',
        mensagem: ''
    });

    const editUser = async e => {
        e.preventDefault();

        if (!(await validate())) return;

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }

        await api.put("/user/user", { id, name, email, perfil, empresaId, equipeId, matricula }, headers)
            .then((response) => {
                setStatus({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setStatus({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setStatus({
                        type: 'error',
                        mensagem: 'Erro: Tente mais tarde!'
                    });
                }
            });
    }

    useEffect(() => {
        const getUser = async () => {

            const headers = {
                'headers': {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }

            await api.get("/user/user/" + id, headers)
                .then((response) => {
                    if (response.data.user) {
                        setName(response.data.user.name);
                        setEmail(response.data.user.email);
                        setPerfil(response.data.user.perfil);
                        setEmpresaId(5);
                        setEquipeId(5);
                        setMatricula(response.data.user.matricula);
                    } else {
                        setStatus({
                            type: 'redWarning',
                            mensagem: "Erro: Usuário não encontrado!"
                        });
                    }

                }).catch((err) => {
                    if (err.response) {
                        setStatus({
                            type: 'redWarning',
                            mensagem: err.response.data.mensagem
                        });
                    } else {
                        setStatus({
                            type: 'redWarning',
                            mensagem: "Erro: Tente mais tarde!"
                        });
                    }
                })
        }

        getUser();
    }, [id]);

    async function validate() {
        let schema = yup.object().shape({
            email: yup.string("Erro: Necessário preencher o campo e-mail!")
                .email("Erro: Necessário preencher o campo e-mail!")
                .required("Erro: Necessário preencher o campo e-mail!"),
            name: yup.string("Erro: Necessário preencher o campo nome!")
                .required("Erro: Necessário preencher o campo nome!"),
                perfil: yup.string("Erro: Necessário preencher o campo perfil!")
                    .required("Erro: Necessário preencher o campo perfil!")
                
        });

        try {
            await schema.validate({ name, email, perfil });
            return true;
        } catch (err) {
            setStatus({ type: 'error', mensagem: err.errors });
            return false;
        }
    }

    const deleteUser = async (idUser) => {
        const response = await servDeleteUser(idUser);
        if (response) {
            if (response.type === "success") {
                setStatus({
                    type: 'redSuccess',
                    mensagem: response.mensagem
                });
            } else {
                setStatus({
                    type: "error",
                    mensagem: response.mensagem
                });
            }
        } else {
            setStatus({
                type: 'error',
                mensagem: 'Erro: Tente mais tarde!'
            });
        }
    }

    return (
        <div>
            <Navbar />
            <div className="content">
                <Sidebar active="users" />

                <div className="wrapper">
                    <div className="row">
                        <div className="top-content-adm">
                            <span className="title-content">Editar Usuário</span>
                            <div className="top-content-adm-right">
                                <Link to="/users">
                                    <button type="button" className="btn-info">Listar</button>
                                </Link>{" "}
                                <Link to={"/view-user/" + id}>
                                    <button type="button" className="btn-primary">Visualizar</button>
                                </Link>{" "}
                                <Link to="#">
                                    <button type="button" className="btn-danger" onClick={() => deleteUser(id)}>Apagar</button>
                                </Link>
                            </div>
                        </div>

                        <div className="alert-content-adm">
                            {status.type === 'redWarning' ?
                                <Redirect to={{
                                    pathname: '/users',
                                    state: {
                                        type: "error",
                                        mensagem: status.mensagem
                                    }
                                }} /> : ""}
                            {status.type === 'redSuccess' ? <Redirect to={{
                                pathname: '/users',
                                state: {
                                    type: "success",
                                    mensagem: status.mensagem
                                }
                            }} /> : ""}
                            {status.type === 'error' ? <p className="alert-danger">{status.mensagem}</p> : ""}
                        </div>

                        <div className="content-adm">
                            <form onSubmit={editUser} className="form-adm">

                                <div className="row-input">
                                    <div className="column">
                                        <label className="title-input">Nome</label>
                                        <input type="text" name="name" id="name" className="input-adm" placeholder="Nome completo do usuário" value={name} onChange={text => setName(text.target.value)} />
                                    </div>
                                </div>

                                <div className="row-input">

                                    <div className="column">
                                        <label className="title-input">E-mail</label>
                                        <input type="email" name="email" id="email" className="input-adm" placeholder="Melhor e-mail do usuário" value={email} onChange={text => setEmail(text.target.value)} />
                                    </div>
                                    </div>


                                    <div className="row-input">

                                    <div className="column">
                                        <label className="title-input">Perfil</label><br />
                                        <select type="text" name="perfil" id="perfil" className="input-adm" placeholder="Perfil Usuário" value={perfil}  onChange={text => setPerfil(text.target.value)}>
                                        
                                          
                                                    <option value="">Selecione o Perfil</option>
                                                    <option value="master">Master</option>
                                                    <option value="administrativo">Administrativo</option>
                                                    <option value="usuario">Usuário</option>
                                        
                                    </select>
                                        
                                    </div>

                                    </div>
{/* 
                                     <div className="row-input">

                                    <div className="column">
                                        <label className="title-input">Empresa</label><br />
                                        <select type="text" name="empresaId" id="empresaId" className="input-adm" placeholder="Perfil Usuário" value={empresaId}  onChange={text => setPerfil(text.target.value)}>
                                        
                                        
                                                    <option value="5">CET-Rio</option>
                                        
                                    </select>
                                        
                                    </div>

                                    </div>


                                <div className="row-input">

                                <div className="column">
                                    <label className="title-input">Equipe</label><br />
                                    <select type="text" name="empresaId" id="empresaId" className="input-adm" placeholder="Perfil Usuário" value={equipeId}  onChange={text => setPerfil(text.target.value)}>
                                    
                                    
                                                <option value="5">CET-Rio</option>
                                    
                                </select>
                                    
                                </div>

                                </div>

                                    

                                    

                                    <div className="row-input">
                                    <div className="column">
                                        <label className="title-input">Matricula</label>
                                        <input type="text" name="matricula" id="matricula" className="input-adm" placeholder="matricula" value={matricula} onChange={text => setMatricula(text.target.value)} />
                                    </div>

                                    </div> */}



                                <button type="submit" className="btn-warning">Salvar</button>

                            </form>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}