import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';


import api from '../../../config/configApi';


export const Cadastros = () => {
    const { state } = useLocation();

    const [data, setData] = useState([]);
    const [unidade, setUnidade] = useState([]);
    
    

    const [search, setSearch] = useState({
        search: 'Vazio',
        pesquisarpor: 'nome'
     
    });

    const valueInput = e => setSearch({ ...search, [e.target.name]: e.target.value });

    const [page, setPage] = useState("");
    const [lastPage, setLastPage] = useState("");


    const [status, setStatus] = useState({
        type: state ? state.type : "",
        mensagem: state ? state.mensagem : ""
    });

    const getUsers = async (page) => {

        if (page === undefined) {
            page = 1;
        }
        setPage(page);

        const headers = {
            'headers': {
                'Authorization': "Bearer " + localStorage.getItem('token')
            }
        }

        await api.post("/cadastrogeral/search", search, headers)
            .then((response) => {
                setData(response.data.cadastros);
                setLastPage(response.data.lastPage);
            }).catch((err) => {
                if (err.response) {
                    setStatus({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setStatus({
                        type: 'error',
                        mensagem: "Erro: Tente mais tarde!"
                    });
                }
            });
    }


    const getUnidades = async (page) => {

        if (page === undefined) {
            page = 1;
        }
        setPage(page);

        const headers = {
            'headers': {
                'Authorization': "Bearer " + localStorage.getItem('token')
            }
        }

        await api.get("/unidade/unidades/" + page, headers)
            .then((response) => {
                setUnidade(response.data);
                console.log(unidade.users);
            }).catch((err) => {
                if (err.response) {
                    setStatus({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setStatus({
                        type: 'error',
                        mensagem: "Erro: Tente mais tarde!"
                    });
                }
            });
    }

    useEffect(() => {
        getUnidades();
        
    }, []);
    
    

    return (<>
          <div id="wrapper">
          <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar />
                        <div className="container-fluid">

                               {/*  <!-- Page Heading --> */}
                               <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                    <h1 className="h4 mb-0 text-gray-800">LOCALIZAR CADASTROS</h1>
                                
                                </div>

        <div className="card shadow mb-4">


            <div className="card-header py-3">

                                <div className="row">

                                    <div className="col-xl-4 col-md-6 mb-4">
                                        <h6 className="m-0 font-weight-bold text-primary">Lista de {data.length} Cadastros</h6>
                                    </div>


                                    <div className="col-xl-2 col-md-2 mb-4">

                                        <input type="text" name="search" id="search" className="form-control  text-uppercase" placeholder="Digite o Nome da Pessoa" onChange={valueInput} />

                                    </div>
                                    <div className="col-xl-2 col-md-2 mb-4">
                                            
                                            <select class="custom-select my-1 mr-sm-2" name="pesquisarpor" id="pesquisarpor" placeholder="Pesquisar Por" onChange={valueInput} required>
                                                <option value="nome" selected>Pesquisar por Nome</option>
                                                <option value="telefone">Telefone</option>
                                                <option value="cpf">CPF</option>
                                            </select>
                                        </div>



                                    <div className="col-xl-2 col-md-2 mb-4">

                                        <Link to={"#"} style={{ textDecoration: 'none' }}>
                                            <button type="button" onClick={() => getUsers()} className="btn btn-primary btn-block">Localizar</button>
                                        </Link>

                                    </div>


                                    <div className="col-xl-2 col-md-6 mb-4">
                                        <Link to="/add-pessoa" style={{ textDecoration: 'none' }}>
                                            <button type="button" className="btn btn-success  btn-block">Cadastrar</button>
                                        </Link>
                                    </div>

                                    
                                    

                                </div>




                <div className="alert-content-adm">
                    {status.type === 'error' ? <p className="alert-danger">{status.mensagem}</p> : ""}
                    {status.type === 'success' ? <p className="alert-success">{status.mensagem}</p> : ""}
                </div>
            </div>
            <div className="table-responsive">
            <div className="card-body">


                
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th hidden>ID</th>
                                <th>Nome</th>
                                <th>Data Nascimento</th>
                                <th>CPF</th>
                                <th>Benefício</th>
                                <th>Unidade</th>
                                <th>Indicação</th>
                                <th>Bairro</th>
                                <th>Telefone</th>
                                <th>Ações</th>
                            </tr>
                        </thead>

                        <tbody>
                            {data.map(user => (
                                <tr key={user.id}>
                                    <td hidden>{user.id}</td>
                                    <td>{user.nome}</td>
                                    <td>{user.datanascimento}</td>
                                    <td>{user.cpf}</td>
                                    <td>{user.beneficio}</td>
                                    <td>{user.unidade}</td>
                                    <td>{user.indicacao}</td>
                                    <td>{user.bairro}</td>
                                    <td> {(user.telefone != "") ? <a href={"https://web.whatsapp.com/send/?phone=55" + user.telefone} target="_blank">{user.telefone}</a> : "Não Informado"}
                                    </td>
                                    <td>


                                        <div className="row">

                                            <div className="col-xl-6 col-md-6 mb-4">
                                            <div className="input-group-append">
                                                <Link to={"/view-pessoa/" + user.id}>
                                                <button className="btn btn-secondary" type="button">
                                                            <i className="fas fa-eye fa-sm"></i> Exibir
                                                        </button>
                                                </Link>
                                                </div>
                                            </div>

                                            <div className="col-xl-6 col-md-6 mb-4">
                                                <div className="input-group-append">
                                                    <Link to={"/edit-pessoa/" + user.id}>
                                                        <button className="btn btn-primary" type="button">
                                                            <i className="fas fa-edit fa-sm"></i> Editar
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>



                                        </div>


                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
        
        </div>
                    </div>
                </div>
            </div>
            <LogOutModal />

    </>



    );
}