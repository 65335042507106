import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';

export const ChangeAlert = (props) => {
    useEffect(() => {
        
        if (props.dirtyCalledTimes === 1) {
            props.setShowChangeAlert(true)
        }
    }, [props.dirtyCalledTimes])


    return (
    <>
        { props.showChangeAlert && 
            <div id="change-alert" className='d-flex justify-content-center fixed-bottom'>
                <Alert variant="warning" onClose={() => props.setShowChangeAlert(false)} dismissible>
                    <Alert.Heading>
                        Atenção, algumas de suas mudanças ainda não foram salvas!
                    </Alert.Heading>
                </Alert> 
            </div>
        }
    </>
    )
}