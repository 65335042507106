import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import {
  Alert, Spinner, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { omit } from 'lodash';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../../config/configApi';
import EmptyState from '../../Shared/EmptyState';
import { brazillianDateTime, brazillianDate, brazillianTime } from '../../../utils/date';
import eventName from '../../../utils/enums/events';
import MudancasModal from './MudancasModal';

export default function EditPessoaEtapas({ pessoaId, users }) {
  const [alertError, setAlertError] = useState({ isVisible: false });
  const [alertSuccess, setAlertSuccess] = useState({ isVisible: false });
  const [etapas, setEtapas] = useState([]);
  const [spinnerEtapas, setSpinnerEtapas] = useState(false);
  const [etapaInputText, setEtapaInputText] = useState('Selecione');
  const [etapaInput, setEtapaInput] = useState('');
  const [realizadoPorInputText, setRealizadoPorInputText] = useState('Selecione uma opção');
  const [realizadoPorInput, setRealizadoPorInput] = useState('');
  const allowedEventstoCreate = omit(eventName, ['REGISTRO', 'ATUALIZACAO', 'ATENDIMENTO']);

  const [observacaoInput, setObservacaoInput] = useState('');
  const [datadoagendamento, setDataDoAgendamento] = useState(
    () => {
      const splitedDate = brazillianDate(new Date(new Date().getTime()).toISOString()).split('/');
      return `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`;
    },
  );
  const [datadoagendamentohora, setDataDoAgendamentoHora] = useState(
    brazillianTime(new Date().toISOString()).split('/'),
  );

  const etapaTooltip = (
    <Tooltip id="etapaInput">
      <strong>{etapaInputText}</strong>
    </Tooltip>
  );

  const atualizarTooltip = (
    <Tooltip id="atualizarTooltip">
      <strong>Atualizar</strong>
    </Tooltip>
  );

  const realizadoPorTooltip = (
    <Tooltip id="realizadoPorInput">
      <strong>{realizadoPorInputText}</strong>
    </Tooltip>
  );

  const etapaOptions = Object.entries(allowedEventstoCreate).map(([code, name]) => ({
    value: code,
    label: name,
  }));

  const getEtapas = async (params) => {
    setSpinnerEtapas(true);
    try {
      const requestHeaders = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };

      const { data } = await api.get(`/pessoa/${pessoaId}/eventos`, {
        headers: requestHeaders,
        params,
      });

      setEtapas(data.eventos);
    } catch (err) {
      setAlertError({ isVisible: true, message: 'Falha ao carregar etapas. Por favor tente novamente.' });
      window.setTimeout(() => { setAlertError({ isVisible: false }); }, 5000);
    }
    setSpinnerEtapas(false);
  };

  const sortedEtapaOptions = etapaOptions.sort((a, b) => a.label.localeCompare(b.label));

  const saveEtapa = async () => {
    try {
      const formEtapa = document.getElementById('form-etapa');

      if (formEtapa.checkValidity()) {
        const headers = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };

        const payload = {
          evento: etapaInput,
          executorId: realizadoPorInput,
          aconteceuEm: new Date(`${datadoagendamento} ${datadoagendamentohora}`),
          observacao: observacaoInput,
        };

        await api.post(`/pessoa/${pessoaId}/eventos`, payload, headers);

        formEtapa.reset();
        setDataDoAgendamentoHora(brazillianTime(new Date().toISOString()).split('/'));
        setObservacaoInput('');
        getEtapas();
      } else {
        formEtapa.reportValidity();
      }
    } catch (error) {
      console.log(error);
      alert('Não foi possível salvar a etapa');
    }
  };

  useEffect(() => {
    getEtapas();
  }, []);

  return (
    <>
      <h4>Registrar Etapa</h4>
      <Form id="form-etapa">
        <div className="form-group row align-items-end">
          <div className="form-group col-md-2">
            <OverlayTrigger placement="right" overlay={etapaTooltip}>
              <Form.Group md="auto" controlId="etapa">
                <Form.Label>Etapa</Form.Label>
                <Select
                  aria-label="Selecione uma opção"
                  name="etapa"
                  value={etapaInput ? { value: etapaInput, label: eventName[etapaInput] } : null}
                  onChange={(selectedOption) => {
                    setEtapaInput(selectedOption ? selectedOption.value : 'hhhh');
                    setEtapaInputText(selectedOption ? selectedOption.label : 'Selecione uma opção');
                  }}
                  options={sortedEtapaOptions.sort((a, b) => a.label.localeCompare(b.label))}
                  isSearchable
                />
              </Form.Group>
            </OverlayTrigger>
          </div>
          <div className="form-group col-md-2">
            <OverlayTrigger placement="right" overlay={realizadoPorTooltip}>
              <Form.Group md="auto" controlId="executorId">
                <Form.Label>Realizada por</Form.Label>
                <Form.Select
                  aria-label="Selecione uma opção"
                  name="executorId"
                  onChange={(e) => {
                    setRealizadoPorInput(
                      e.target.value,
                    );

                    setRealizadoPorInputText(
                      e.target.options[e.target.selectedIndex].text,
                    );
                  }}
                  required
                >
                  <option value="">Selecione uma opção</option>
                  { users?.map((user) => (
                    <option
                      key={user.id}
                      value={user.id}
                    >
                      {user.nome}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </OverlayTrigger>
          </div>
          <div className="form-group col-md-2">
            <label htmlFor="data">Data</label>
            <input
              type="date"
              name="data"
              id="data"
              className="form-control"
              value={datadoagendamento}
              onChange={(text) => setDataDoAgendamento(text.target.value)}
              required
            />
          </div>
          <div className="form-group col-md-2">
            <label htmlFor="hora">Hora</label>
            <input
              type="time"
              name="hora"
              id="hora"
              className="form-control"
              value={datadoagendamentohora}
              onChange={(text) => setDataDoAgendamentoHora(text.target.value)}
              required
            />
          </div>
          <div className="form-group col-md-2">
            <Form.Label>Observação</Form.Label>
            <Form.Control
              as="textarea"
              rows={1}
              value={observacaoInput}
              onChange={(e) => setObservacaoInput(e.target.value)}
              type="text"
              name="observacao"
            />
          </div>

          <div className="form-group col-md-2">
            <button type="button" onClick={saveEtapa} className="btn btn-success  btn-block">Salvar</button>
          </div>
        </div>
      </Form>

      <div className="row">
        <div className="col-auto">
          <h4> Etapas concluídas </h4>
        </div>
        <div className="col-auto">
          <OverlayTrigger placement="right" overlay={atualizarTooltip}>
            <button type="button" className="btn btn-outline-dark" onClick={() => getEtapas()}>
              <FontAwesomeIcon icon={faRefresh} />
            </button>
          </OverlayTrigger>
        </div>
      </div>

      { !spinnerEtapas
        ? (
          <div className="table-responsive">
            <div className="card-body">
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th hidden>ID</th>
                    <th>
                      Etapa
                    </th>
                    <th>Executado por</th>
                    <th>Data</th>
                    <th>Registado por</th>
                    <th>Observações</th>
                    <th>Mudanças</th>
                  </tr>
                </thead>
                <tbody>
                  {etapas?.map((etapa) => (
                    <tr key={etapa.id}>
                      <td hidden>{etapa.id}</td>
                      <td className="align-middle">{eventName[etapa.evento] || 'Evento Desconhecido'}</td>
                      <td className="align-middle">
                        <a href={`/view-user/${etapa.executor?.id}`} target="_blank" rel="noreferrer">
                          {etapa.executor?.nome}
                        </a>
                      </td>
                      <td className="align-middle">{brazillianDateTime(etapa.aconteceuEm)}</td>
                      <td className="align-middle">
                        {etapa.relator?.id ? (
                          <a href={etapa.relator?.id ? `/view-user/${etapa.relator?.id}` : ''} target="_blank" rel="noreferrer">
                            {etapa.relator?.nome}
                          </a>
                        ) : (
                          <span>
                            Sistema
                          </span>
                        )}
                      </td>
                      <td className="align-middle">{etapa.observacao}</td>
                      <td className="align-middle">
                        {(etapa.evento === 'ATUALIZACAO' || etapa.evento === 'REGISTRO') && etapa.changedFields && (
                          <MudancasModal etapa={etapa} />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <EmptyState isActive={!etapas.length} />
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner
              as="span"
              animation="border"
              size="xl"
              role="status"
              aria-hidden="true"
            >
              <span className="sr-only">Carregando...</span>
            </Spinner>
          </div>
        )}

      { alertError.isVisible
        && (
        <div className="fixed-top mt-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-6">
                <Alert variant="danger" onClose={() => setAlertError({ isVisible: false })} dismissible>
                  <p className="text-center mt-3">
                    {alertError.message}
                  </p>
                </Alert>
              </div>
            </div>
          </div>
        </div>
        )}
      { alertSuccess.isVisible
        && (
        <div className="fixed-top mt-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-6">
                <Alert variant="success" onClose={() => setAlertSuccess({ isVisible: false })} dismissible>
                  <p className="text-center mt-3">
                    {alertSuccess.message}
                  </p>
                </Alert>
              </div>
            </div>
          </div>
        </div>
        )}
    </>
  );
}

EditPessoaEtapas.defaultProps = {
  users: [],
};

EditPessoaEtapas.propTypes = {
  pessoaId: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      nome: PropTypes.string,
      id: PropTypes.number,
      celular: PropTypes.string,
      logradouro: PropTypes.string,
      numero: PropTypes.string,
      complemento: PropTypes.string,
      bairro: PropTypes.string,
      cidade: PropTypes.string,
      cep: PropTypes.string,
      datanascimento: PropTypes.string,
      email: PropTypes.string,
      password: PropTypes.string,
      recover_password: PropTypes.string,
      image: PropTypes.string,
      matricula: PropTypes.string,
      urlfoto: PropTypes.string,
      empresaId: PropTypes.number,
      equipeId: PropTypes.number,
      outraId: PropTypes.number,
      perfilId: PropTypes.number,
    }),
  ),
};
