import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';

import * as yup from 'yup';

import api from '../../../config/configApi';
import { servDeleteUser } from '../../../services/servDeleteUser';

export const EditChamado = (props) => {

    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [dataabertura, setDataAbertura] = useState(''); 
    const [whatsapp, setWhatsapp] = useState('');
    const [sala, setSala] = useState('');
    const [descricaoatendimento, setDescricaoAtendimento] = useState('');
    const [situacao, setSituacao] = useState('');
    const [datafechamento, setDataFechamento] = useState(''); 
    const [solucao, setSolucao] = useState('');


    const [id] = useState(props.match.params.id);

    const [status, setStatus] = useState({
        type: '',
        mensagem: ''
    });

    const editUser = async e => {
        e.preventDefault();

        if (!(await validate())) return;

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }

        

        await api.put("/user/user", { id, dataabertura, nome, whatsapp, sala, descricaoatendimento, situacao, email, datafechamento, solucao }, headers)
            .then((response) => {
                setStatus({
                    type: 'redSuccess',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setStatus({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setStatus({
                        type: 'error',
                        mensagem: 'Erro: Tente mais tarde!'
                    });
                }
            });
    }

    useEffect(() => {
        const getUser = async () => {

            const headers = {
                'headers': {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }

            await api.get("/chamado/chamado/" + id, headers)
                .then((response) => {
                    if (response.data.user) {
                        setNome(response.data.user.nome);
                        setEmail(response.data.user.email);
                        
                       /*  setDataAbertura(response.data.user.dataabertura);
                        setWhatsapp(response.data.user.whatsapp);
                        setSala(response.data.user.sala);
                        setDescricaoAtendimento(response.data.user.descricaoatendimento);
                        setSituacao(response.data.user.situacao);
                        setDataFechamento(response.data.user.datafechamento); 
                        setSolucao(response.data.user.solucao); */
                       
                    } else {
                        setStatus({
                            type: 'redWarning',
                            mensagem: "Erro: Usuário não encontrado!"
                        });
                    }

                }).catch((err) => {
                    if (err.response) {
                        setStatus({
                            type: 'redWarning',
                            mensagem: err.response.data.mensagem
                        });
                    } else {
                        setStatus({
                            type: 'redWarning',
                            mensagem: "Erro: Tente mais tarde!"
                        });
                    }
                })
        }

        getUser();
    }, [id]);

    async function validate() {
        let schema = yup.object().shape({
            email: yup.string("Erro: Necessário preencher o campo e-mail!")
                .email("Erro: Necessário preencher o campo e-mail!")
                .required("Erro: Necessário preencher o campo e-mail!"),
            nome: yup.string("Erro: Necessário preencher o campo nome!")
                .required("Erro: Necessário preencher o campo nome!")

        });

        try {
            await schema.validate({ nome, email });
            return true;
        } catch (err) {
            setStatus({ type: 'error', mensagem: err.errors });
            return false;
        }
    }

    const deleteUser = async (idUser) => {
        const response = await servDeleteUser(idUser);
        if (response) {
            if (response.type === "success") {
                setStatus({
                    type: 'redSuccess',
                    mensagem: response.mensagem
                });
            } else {
                setStatus({
                    type: "error",
                    mensagem: response.mensagem
                });
            }
        } else {
            setStatus({
                type: 'error',
                mensagem: 'Erro: Tente mais tarde!'
            });
        }
    }


    return (

        <>
        <div id="wrapper">
            <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Navbar />
                    <div className="container-fluid">
        <div class="card shadow mb-4">
                                <div class="card-header py-3">

                                    <div className="alert-content-adm">
                                        {status.type === 'redWarning' ?
                                            <Redirect to={{
                                                pathname: '/users',
                                                state: {
                                                    type: "error",
                                                    mensagem: status.mensagem
                                                }
                                            }} /> : ""}
                                        {status.type === 'redSuccess' ? <Redirect to={{
                                            pathname: '/users',
                                            state: {
                                                type: "success",
                                                mensagem: status.mensagem
                                            }
                                        }} /> : ""}
                                        {status.type === 'error' ? <p className="alert-danger">{status.mensagem}</p> : ""}
                                    </div>




                                    <div className="row">

                                        <div className="col-xl-4 col-md-6 mb-4">
                                            <h6 class="m-0 font-weight-bold text-primary">Editar Usuário: {nome} </h6>
                                        </div>

                                        <div className="col-xl-2 col-md-6 mb-4">

                                            <Link to="/users"><button type="button" className="btn btn-info btn-block">Listar</button></Link>{" "}

                                        </div>
                                        <div className="col-xl-2 col-md-6 mb-4">
                                            <Link to={"/view-user/" + id}> <button type="button" className="btn btn-primary  btn-block">Visualizar</button>  </Link>{" "}

                                        </div>
                                        <div className="col-xl-2 col-md-6 mb-4">
                                            <Link to="#"> <button type="button" className="btn btn-danger  btn-block" onClick={() => deleteUser(id)}>Apagar</button> </Link>
                                        </div>
                                        <div className="col-xl-4 col-md-6 mb-4">
                                        </div>

                                    </div>



                                </div>
                                <div class="card-body">
                                    <form onSubmit={editUser} className="form-adm">


                                        <div class="form-group row">
                                            <label for="staticEmail" className="col-sm-2 col-form-label">Nome</label>
                                            <div className="col-sm-10">
                                                <input type="text" name="nome" id="nome"  className="form-control" placeholder="Nome completo do usuário" value={nome} onChange={text => setNome(text.target.value)} />
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label for="staticEmail" className="col-sm-2 col-form-label">E-mail</label>
                                            <div className="col-sm-10">
                                                <input type="email" name="email" id="email" className="form-control"  value={email} onChange={text => setEmail(text.target.value)} />
                                            </div>
                                        </div>

                                   

                                        <div class="form-group row">

                                        <button type="submit" className="btn btn-success  btn-block">Salvar</button>
                                        </div>
                                        

                                    </form>
                                </div>


                            </div>

                            </div>
                    </div>
                </div>
            </div>
            <LogOutModal />
        </>
    )
}